import { useState } from "react";
import { Form } from "./form";
import ModalC from "./modal";


export const GetPresentation = () => {
    const [success, setSuccess] = useState(false);

    return (<section className="get_present plr">
        <div className="wmain">
            <div className="tm">Получить презентацию</div>
            <form className="get_present_form">
                <Form
                    fields={[
                        {
                            name: "name",
                            placeholder: "Имя",
                            required: false,
                            type: "text",
                            class: "in_style",
                        },
                        {
                            name: "phone",
                            placeholder: "Телефон",
                            required: true,
                            type: "text",
                            class: "in_style",
                        },
                    ]}
                    hidden={`Скачать презентацию`}
                    btnTitle={'Скачать'}
                    celtype={"getPresent"}
                    template={2}
                    personal={true}
                    formClassName={"form_line"}
                    callback={() => { setSuccess(true) }}
                    close={() => { setSuccess(false) }}
                />
            </form>
        </div>
        {success ? <ModalC
                success={success}
                position={window.scrollY}
                close={() => { setSuccess(false) }}
            /> : <div></div>}
    </section>
    )
}

export default GetPresentation;
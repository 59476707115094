import { useContext } from "react"
import { BlocksContext } from "../context/blocksContext"

export const Footer = () => {
    const blocks = useContext(BlocksContext)

    const clickMenu = (e) => {
        e.preventDefault()
        let popup = e.currentTarget.getAttribute("href")
        if (blocks.blocks < 12) {
            blocks.setMenuClick(true)
            blocks.setBlocks(12)
            setTimeout(() => {
                window.scrollTo({
                    top: document.querySelector("." + popup).offsetTop - 200,
                    behavior: "smooth"
                })
                setTimeout(() => {
                    blocks.setMenuClick(false)
                }, 500)

            }, 1200)

        } else {
            window.scrollTo({
                top: document.querySelector("." + popup).offsetTop - 200,
                behavior: "smooth"
            })
        }
       
    }

    return (
        <section className="footer plr">
            <div className="wmain">
                <div className="f_left">
                    <div className="fl__l">
                        <div>© 2025 «ЖК Олимп» <br />Все права защищены.</div>
                        {/* <div>ПАО Сбербанк: генеральная лицензия <br />№1481 от 11.08.2015</div> */}
                        <div className="f_reate">
                            <a href="https://leadactiv.ru/" target="_blank">
                                Разработка и лидогенерация:
                                <img src="img/create_logo.svg" />
                            </a>
                        </div>
                    </div>
                    <div className="fl__r">
                        <div className="f_nav">
                            <a onClick={clickMenu} href="about">Проектная документация</a>
                            <a onClick={clickMenu} href="about">О застройщике</a>
                            <a onClick={clickMenu} href="plan">Планировки</a>
                            <a onClick={clickMenu} href="buy">Как купить</a>
                            <a onClick={clickMenu} href="shema">Как добраться</a>
                            <a onClick={clickMenu} href="contact">Контакты</a>
                        </div>
                    </div>
                </div>
                <div className="f_right">
                    Продолжая использовать наш сайт и отправляя любую форму на сайте, Вы даете согласие на обработку файлов Cookies и других пользовательских данных, в соответствии с <a href="/docs/ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ЖК ОЛИМП.docx" target="_blank">политикой конфиденциальности</a> данного сайта, а также соглашаетесь на получение рассылки на электронную почту, указанную в заявке.Запретить обработку Cookies можно в настройках Вашего браузера. Любая информация, представленная на данном сайте, носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями статьи 437 ГК РФ. С проектной декларацией можно ознакомиться на <a target="_blank" href="https://наш.дом.рф/сервисы/каталог-новостроек/объект/59996">наш.дом.рф</a>
                </div>
            </div>
        </section>
    )
}

export default Footer

import React, { useEffect, useState } from "react"
import ModalC from "./modal"
import 'rc-slider/assets/index.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper'

export const Plan = () => {
    const [flats, setFlats] = useState([])
    const [type, setType] = useState([1,2,3])
    const [isOpen, setIsOpen] = useState(null)
    const [flatPopup, setFlatPopup] = useState(null)
    const [flatsToShow, setFlatsToShow] = useState(8)
    const [swiper, setSwiper] = useState(null)
    const [filterdFlats, setFiltredFlats] = useState([])


    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_URL + "/flats_macro.php", {})
            .then(res => res.json())
            .then((result) => {
                console.log(result)

                setFlats(result)
                setFiltredFlats(result)

            })
    }, [])

    const moreClick = (e) => {
        e.preventDefault()
        setFlatsToShow(flatsToShow + 4)
    }

    useEffect(() => {
        let newFiltredFlats = []
        flats.forEach((flat) => {
            if (type.includes(flat.rooms)) {
                newFiltredFlats.push(flat)
            }
        })

        setFiltredFlats(newFiltredFlats)
    }, [type])

    const flatClick = (e) => {
        e.preventDefault()
    }

    const kvTitle = (classKv) => {
        switch (classKv) {
            case 0:
                return "Квартира-студия"
            case 1:
                return "1-комнатная квартира"
            case 2:
                return "2-комнатная квартира"
            case 3:
                return "3-комнатная квартира"
        }
    }

    const addRemoveType = (typeToAdd) => {
        let newTypes = [...type]
        if (newTypes.length == 3) {
            newTypes = []
        }

        if (newTypes.includes(typeToAdd)) {
            newTypes = [...newTypes.filter((type) => type != typeToAdd)]
            console.log(newTypes)
        } else {
            newTypes.push(typeToAdd)
        }
       
        if (newTypes.length == 0) {
            newTypes = [1,2,3]
        }
        setType([...newTypes])
       
    }

    const addAll = () => {
        let newTypes = [1,2,3]
        setType([...newTypes])
    }


    return (
        <React.Fragment>
            <section class="plan plr">
                <div class="wmain">
                    <div class="tm">
                        Посмотрите планировки и узнайте цены
                    </div>
                    <div class="plan_nav">
                        <div onClick={()=>{setType([1,2,3])}} className={type.includes(1) && type.includes(2) && type.includes(3)?"act":""}>Все</div>
                        <div onClick={()=>{setType([1])}} className={type.includes(1) && (!type.includes(2) || !type.includes(3))?"act":""}>1-комнатные</div>
                        <div onClick={()=>{setType([2])}} className={type.includes(2) && (!type.includes(1) || !type.includes(3))?"act":""}>2-комнатные</div>
                        <div onClick={()=>{setType([3])}} className={type.includes(3) && (!type.includes(1) || !type.includes(2))?"act":""}>3-комнатные</div>
                    </div>
                    <Swiper
                        className='plan_slider'
                        modules={Navigation}
                        speed={400}
                        slidesPerView={1}
                        spaceBetween={60}
                        loop={true}
                        autoHeight={true}
                        lazy={true}
                        onInit={(swiper) => { setSwiper(swiper) }}
                        breakpoints={{
                            580: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            860: {
                                slidesPerView: 3,
                                spaceBetween: 32,
                            },
                            1280: {
                                slidesPerView: 3,
                                spaceBetween: 32,
                            },
                        }}
                    >


                        {filterdFlats.sort((a,b)=>a.rooms - b.rooms).map((flat, index) => {
                            return <SwiperSlide>
                                <div class="plan_slide" onClick={(e) => { e.preventDefault(); setIsOpen(true); setFlatPopup(flat) }}>
                                    <div class="plan_slide_img"><img src={flat["img"]} /></div>
                                    <div class="plan_slide_name">
                                        {kvTitle(flat["rooms"])}, {flat["full_area"]} м²
                                    </div>
                                    <div class="align_center">
                                        <div class="btn_main" onClick={(e) => { e.preventDefault(); setIsOpen(true); setFlatPopup(flat) }}>Узнать стоимость</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        })}

                        <div class="plan_slider_nav">
                            <div class="swiper-button-prev" onClick={()=>{swiper.slidePrev(); setShowArrow()}}>
                                <svg width="66.008057" height="26.643555" viewBox="0 0 66.0081 26.6436" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <path id="Arrow 4" d="M6.83 11.32L14.73 3.42C15.52 2.63 15.52 1.38 14.73 0.59C13.94 -0.2 12.69 -0.2 11.9 0.59L0.59 11.9C-0.2 12.69 -0.2 13.94 0.59 14.73L11.9 26.04C12.69 26.84 13.94 26.84 14.73 26.04C15.52 25.25 15.52 24.01 14.73 23.22L6.83 15.32L64 15.32C65.12 15.32 66 14.44 66 13.32C66 12.2 65.12 11.32 64 11.32L6.83 11.32Z" fill="#1E1E1E" fill-opacity="1.000000" fill-rule="evenodd" />
                                </svg>
                            </div>
                            <div class="swiper-button-next" onClick={()=>{swiper.slideNext(); setShowArrow()}}>
                                <svg width="66.008057" height="26.643555" viewBox="0 0 66.0081 26.6436" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <path id="Arrow 3" d="M59.17 11.32L51.27 3.42C50.48 2.63 50.48 1.38 51.27 0.59C52.06 -0.2 53.3 -0.2 54.1 0.59L65.41 11.9C66.2 12.69 66.2 13.94 65.41 14.73L54.1 26.04C53.3 26.84 52.06 26.84 51.27 26.04C50.48 25.25 50.48 24.01 51.27 23.22L59.17 15.32L2 15.32C0.87 15.32 0 14.44 0 13.32C0 12.2 0.87 11.32 2 11.32L59.17 11.32Z" fill="#1E1E1E" fill-opacity="1.000000" fill-rule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </Swiper>

                </div>
            </section >



            {
                isOpen ? <ModalC
                    title={"Узнайте стоимость квартиры на сегодня"}
                    position={window.scrollY}
                    flat={flatPopup}
                    fields={[
                        {
                            name: "name",
                            placeholder: "Ваше имя",
                            required: false,
                            type: "text",
                            class: "in_style",
                        },
                        {
                            name: "phone",
                            placeholder: "Ваш телефон",
                            required: true,
                            type: "text",
                            class: "in_phone",
                        },
                    ]}
                    hidden={"Узнать стоимость"}
                    btnTitle={"Узнать стоимость"}
                    celtype={"getCost"}
                    template={1}
                    callback={() => { console.log("success") }}
                    personal={true}
                    background={"url(img/pu_flat_bg.jpeg)"}
                    close={() => { setIsOpen(false) }}
                /> : <div></div>
            }
        </React.Fragment >
    )
}

export default Plan
import React, { useState } from "react"
import ModalC from "./modal"

export const HowToBuy = () => {
    const [modalType, setModalType] = useState(-1)
    return (
        <React.Fragment>
            <section className="otdelka buy plr">
                <div className="wmain">
                    <div className="tm">Способы покупки</div>
                    <ul className="otdelka_list buy_list">
                        <li style={{"background": "url(img/buy_img1.jpeg)"}} onClick={(e)=>{e.preventDefault(); setModalType(1)}}>
                            <div className="ol_title">100% оплата</div>
                            <div className="align_center">
                                <div className="btn_main" onClick={(e)=>{e.preventDefault(); setModalType(1)}}>Узнать подробнее</div>
                            </div>
                        </li>
                        <li style={{"background": "url(img/buy_img2.jpeg)"}} onClick={(e)=>{e.preventDefault(); setModalType(2)}}>
                            <div className="ol_title">Рассрочка</div>
                            <div className="align_center">
                                <div className="btn_main" onClick={(e)=>{e.preventDefault(); setModalType(2)}}>Узнать подробнее</div>
                            </div>
                        </li>
                        <li style={{"background": "url(img/buy_img3.jpeg)"}} onClick={(e)=>{e.preventDefault(); setModalType(0)}}>
                            <div className="ol_title">Ипотека</div>
                            <div className="align_center">
                                <div className="btn_main" onClick={(e)=>{e.preventDefault(); setModalType(0)}}>Узнать подробнее</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            {
                {
                    0: <ModalC
                        fields={[
                            {
                                name: "vznos",
                                placeholder: "",
                                type: "select",
                                placeholder: "Первоначальный взнос",
                                class: "dop-info",
                                options: [
                                    "от 300 т.р. до 500 т.р.",
                                    "от 500 т.р. до 700 т.р.",
                                    "от 1 млн.р. до 1,2 млн.р.",
                                    "от 1,2 млн.р. до 1,5 млн.р.",
                                    "от 1,5 млн.р. до 2 млн.р.",
                                ],
                            },
                            {
                                name: "name",
                                placeholder: "Ваше имя",
                                required: false,
                                type: "text",
                                class: "in_name",
                            },
                            {
                                name: "srok",
                                placeholder: "",
                                type: "select",
                                placeholder: "На срок",
                                class: "dop-info",
                                options: [
                                    "5 лет",
                                    "10 лет",
                                    "15 лет",
                                    "20 лет",
                                    "25 лет",
                                    "30 лет",
                                ],
                            },

                            {
                                name: "phone",
                                placeholder: "Ваш телефон",
                                required: true,
                                type: "text",
                                class: "in_phone",
                            },

                        ]}
                        position={window.scrollY}
                        title={"Получите расчет ежемесячного платежа по ипотеке"}
                        hidden={`Получите расчет ежемесячного платежа по ипотеке`}
                        btnTitle={'Получить расчет'}
                        celtype={"getIpot"}
                        template={2}
                        personal={true}
                        callback={() => { console.log("success") }}
                        background={"url(img/pu_oplata_bg.jpeg)"}
                        formClassName={"pu_ipoteka"}
                        close={() => { setModalType(-1) }}
                    />,
                    2: <ModalC
                        fields={[
                            {
                                name: "vznos",
                                placeholder: "",
                                type: "select",
                                placeholder: "Первоначальный взнос",
                                class: "dop-info",
                                options: [
                                    "от 300 т.р. до 500 т.р.",
                                    "от 500 т.р. до 700 т.р.",
                                    "от 1 млн.р. до 1,2 млн.р.",
                                    "от 1,2 млн.р. до 1,5 млн.р.",
                                    "от 1,5 млн.р. до 2 млн.р.",
                                ],
                            },
                            {
                                name: "name",
                                placeholder: "Ваше имя",
                                required: false,
                                type: "text",
                                class: "in_name",
                            },
                            {
                                name: "srok",
                                placeholder: "",
                                type: "select",
                                placeholder: "Срок",
                                class: "dop-info",
                                options: [
                                    "3 месяца",
                                    "6 месяцев",
                                    "12 месяцев",
                                ],
                            },

                            {
                                name: "phone",
                                placeholder: "Ваш телефон",
                                required: true,
                                type: "text",
                                class: "in_phone",
                            },

                        ]}
                        position={window.scrollY}
                        hidden={`Получите расчет ежемесячного платежа по рассрочке`}
                        btnTitle={'Получить расчет'}
                        title={"Получите расчет ежемесячного платежа по рассрочке"}
                        celtype={"getRassrochka"}
                        template={2}
                        personal={true}
                        background={"url(img/pu_oplata_bg.jpeg)"}
                        formClassName={"pu_ipoteka"}
                        callback={() => { console.log("success") }}
                        close={() => { setModalType(-1) }}
                    />,
                    1: <ModalC
                        fields={[
                            {
                                name: "name",
                                placeholder: "Ваше имя",
                                required: false,
                                type: "text",
                                class: "in_name",
                            },
                            {
                                name: "phone",
                                placeholder: "Ваш телефон",
                                required: true,
                                type: "text",
                                class: "in_phone",
                            },

                        ]}
                        position={window.scrollY}
                        hidden={`Узнать подробности по 100% оплате`}
                        btnTitle={'Узнать подробнее'}
                        title={"Узнать подробности по 100% оплате"}
                        celtype={"getFullPayment"}
                        template={2}
                        personal={true}
                        background={"url(img/pu_oplata_bg.jpeg)"}
                        callback={() => { console.log("success") }}
                        close={() => { setModalType(-1) }}
                    />,


                }[modalType]
            }
        </React.Fragment>
    )
}

export default HowToBuy
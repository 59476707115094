import React, { useContext, useEffect, useState } from 'react'
import { BlocksContext } from "../context/blocksContext"
import ModalC from "./modal"

export const Menu = () => {
    const blocks = useContext(BlocksContext)
    const [isOpen, setIsOpen] = useState(false)
    const [mobileOpen, setMobileOpen] = useState(false)

    const clickMenu = (e) => {
        e.preventDefault()
        let popup = e.currentTarget.getAttribute("href")
        if (blocks.blocks < 12) {
            blocks.setMenuClick(true)
            blocks.setBlocks(12)
            setTimeout(() => {
                window.scrollTo({
                    top: document.querySelector("." + popup).offsetTop - 200,
                    behavior: "smooth"
                })
                setTimeout(() => {
                    blocks.setMenuClick(false)
                }, 500)

                setMobileOpen(false)
            }, 1200)

        } else {
            window.scrollTo({
                top: document.querySelector("." + popup).offsetTop - 200,
                behavior: "smooth"
            })
            setMobileOpen(false)
        }
       
    }

    const handleScroll = (event) => {
        try {
            if (window.scrollY > 5) {
                document.querySelector('.hl_top').classList.add('hl_top_act')
            } else if (document.querySelector('.hl_top').classList.contains('hl_top_act')) {
                document.querySelector('.hl_top').classList.remove('hl_top_act')
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
    }, [])

    return (
        <React.Fragment>
            <div className={mobileOpen?"hl_top plr hl_top_open":"hl_top plr"}>
                <div className="hl_l">
                    <img
                        onClick={(e) => {
                            e.preventDefault();
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth"
                            })
                        }}
                        style={{cursor: "pointer"}}
                        className="logo" src="img/logo.svg" />

                    <div className="hl_city" style={{cursor:"pointer"}} onClick={clickMenu} href="contact">
                        <img src="img/hl_geo_ico.svg"  />г. Георгиевск
                    </div>
                </div>
                <div className="hl_btn_nav" onClick={()=>{setMobileOpen(!mobileOpen)}}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="hl_nav">
                    <a onClick={clickMenu} href="plan">Планировки</a>
                    <a onClick={clickMenu} href="otdelka">Отделка</a>
                    <a onClick={clickMenu} href="shema">Как добраться</a>
                    <a onClick={clickMenu} href="buy">Как купить</a>
                    <a onClick={clickMenu} href="contact">Контакты</a>
                </div>
                <div className="hl_r">
                    <a className="hl_phone roistat_h" href="tel:+79189488592" target="_blank">+7 (918) 948-85-92</a>
                    <a className="btn_main" onClick={(e) => { e.preventDefault(); setIsOpen(true) }}>Заказать звонок</a>
                </div>
            </div>
            {isOpen ? <ModalC
                title={"Закажите звонок"}
                position={window.scrollY}
                puForm={true}
                fields={[
                    {
                        name: "name",
                        placeholder: "Ваше имя",
                        required: false,
                        type: "text",
                        class: "in_style",
                    },
                    {
                        name: "phone",
                        placeholder: "Ваш телефон",
                        required: true,
                        type: "text",
                        class: "in_style",
                    },
                ]}
                hidden={"Заказать звонок"}
                btnTitle={"Заказать звонок"}
                celtype={"getCalls"}
                template={2}
                callback={() => { console.log("success") }}
                personal={true}
                checkbox={true}
                background={"url(img/pu_call_bg.jpeg)"}
                close={() => { setIsOpen(false) }}
            /> : <div></div>}
        </React.Fragment>
    )
}
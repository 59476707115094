import { YMaps, Map, ZoomControl, Placemark } from 'react-yandex-maps';
import { useState, Fragment, useEffect } from 'react';
import ModalC from "./modal"

export const Contacts = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [mapConfig, setMapConfig] = useState(false) 
    const [phone, setPhone] = useState("+7 (918) 948-85-92")
    const mapConfs = {
        "desktop": {
            "center" : [44.123640, 43.445747],
            "baloon": [44.123640, 43.450747],
        },

        "mobile": {
            "center" : [44.121640, 43.447547],
            "baloon": [44.123640, 43.450747],
        },
    }

    useEffect(()=>{
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            setMapConfig(mapConfs.mobile)
        } else {
            setMapConfig(mapConfs.desktop)
        }
    },[])

    useEffect(()=>{
        setTimeout(()=>{
            setPhone(document.querySelector('.hl_phone').innerHTML)
        }, 1000)
    },[])
    
    return (
        <Fragment>
            <section className="contact plr">
                <YMaps>
                    <div id="map" style={{ width: "100%", height: "100%", position: "relative" }}>
                        {mapConfig?<Map defaultState={{ center: mapConfig.center, zoom: 16, controls: [] }} style={{ width: "100%", height: "100%", position: "absolute" }}  >
                            <ZoomControl options={{ float: 'left' }} />
                            <Placemark geometry={mapConfig.baloon}
                                options={{
                                    iconLayout: 'default#image',
                                    hideIconOnBalloonOpen: false,
                                    iconImageSize: [120, 40],
                                    iconImageOffset: [-60, -40],
                                    cursor: 'default',
                                    iconShadow: true,
                                    balloonclose: true,
                                    iconImageHref: 'img/logo.svg',
                                    balloonPanelMaxMapArea: 0,
                                }}
                            />
                        </Map>:<></>}
                    </div>
                </YMaps>
                <div className="contact_inner">
                    <div className="wmain">
                        <div className="content_plashka">
                            <div className="cp__title">Отдел продаж на объекте:</div>
                            <div className="cp__list">
                                <div>Адрес: г. Георгиевск, ул. Филатова, д. 60/3</div>
                                <div>Телефон: {phone}</div>
                                <div>
                                    График работы:<br/>
                                    пн-пт: с 09:00 до 18:00<br/>
                                    сб: с 09:00 до 14:00<br/>
                                    вс: выходной
                                </div>
                            </div>
                            <div className="btn_main" onClick={(e) => { e.preventDefault(); setIsOpen(true) }}>
                                Записаться на консультацию
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {isOpen ? <ModalC
                title={"Запишитесь на консультацию"}
                position={window.scrollY}
                puForm={true}
                fields={[
                    {
                        name: "name",
                        placeholder: "Ваше имя",
                        required: false,
                        type: "text",
                        class: "in_style",
                    },
                    {
                        name: "phone",
                        placeholder: "Ваш телефон",
                        required: true,
                        type: "text",
                        class: "in_style",
                    },
                ]}
                hidden={"Записаться на консультацию"}
                btnTitle={"Записаться"}
                celtype={"getConsult"}
                template={2}
                callback={() => { console.log("success") }}
                personal={true}
                checkbox={true}
                background={"url(img/pu_call_bg.jpeg)"}
                close={() => { setIsOpen(false) }}
            /> : <div></div>}
        </Fragment>
    )
}

export default Contacts
import React, { useState } from "react"
import ModalC from "./modal"

export const Otdelka = () => {
    const [modalType, setModalType] = useState(-1)
    return (
        <React.Fragment>
            <section className="otdelka plr">
                <div className="wmain">
                    <div className="tm">Типы отделки</div>
                    <ul className="otdelka_list">
                        <li style={{ "background": "url(img/otdelka_img1.jpeg)" }} onClick={()=>{setModalType(0)}}>
                            <div className="ol_title">Без отделки</div>
                            <div className="ol_btn">
                                Узнать <br />подробнее
                            </div>
                        </li>
                        <li style={{ "background": "url(img/otdelka_img2.jpeg)" }} onClick={()=>{setModalType(2)}}>
                            <div className="ol_title">Предчистовая отделка</div>
                            <div className="ol_btn">
                                Узнать <br />подробнее
                            </div>
                        </li>
                        <li style={{ "background": "url(img/otdelka_img3.jpeg)" }} onClick={()=>{setModalType(1)}}>
                            <div className="ol_title">Чистовая отделка</div>
                            <div className="ol_btn">
                                Узнать <br />подробнее
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            {
                {
                    0: <ModalC
                        fields={[
                            {
                                name: "name",
                                placeholder: "Ваше имя",
                                required: false,
                                type: "text",
                                class: "in_name",
                            },
                            {
                                name: "phone",
                                placeholder: "Ваш телефон",
                                required: true,
                                type: "text",
                                class: "in_phone",
                            },
                        ]}
                        position={window.scrollY}
                        title={"Узнать подробнее о квартире без отделки"}
                        hidden={`Узнать подробнее о квартире без отделки`}
                        btnTitle={'Узнать подробнее'}
                        celtype={"getWithoutRemont"}
                        template={2}
                        personal={true}
                        callback={() => { console.log("success") }}
                        background={"url(img/pu_otdelka_bg.jpeg)"}
                        close={() => { setModalType(-1) }}
                    />,
                    1: <ModalC
                        fields={[
                            {
                                name: "name",
                                placeholder: "Ваше имя",
                                required: false,
                                type: "text",
                                class: "in_name",
                            },
                            {
                                name: "phone",
                                placeholder: "Ваш телефон",
                                required: true,
                                type: "text",
                                class: "in_phone",
                            },
                        ]}
                        position={window.scrollY}
                        hidden={`Узнайте подробнее о чистовой отделке`}
                        btnTitle={'Узнать подробнее'}
                        title={"Узнайте подробнее о чистовой отделке"}
                        celtype={"getCleanRemont"}
                        template={2}
                        personal={true}
                        background={"url(img/pu_otdelka_bg.jpeg)"}
                        
                        callback={() => { console.log("success") }}
                        close={() => { setModalType(-1) }}
                    />,
                    2: <ModalC
                        fields={[
                            {
                                name: "name",
                                placeholder: "Ваше имя",
                                required: false,
                                type: "text",
                                class: "in_name",
                            },
                            {
                                name: "phone",
                                placeholder: "Ваш телефон",
                                required: true,
                                type: "text",
                                class: "in_phone",
                            },

                        ]}
                        position={window.scrollY}
                        hidden={`Узнайте подробнее о предчистовой отделке`}
                        btnTitle={'Узнать подробнее'}
                        title={"Узнайте подробнее о предчистовой отделке"}
                        celtype={"getPreCleanRemont"}
                        template={2}
                        personal={true}
                        background={"url(img/pu_otdelka_bg.jpeg)"}
                        callback={() => { console.log("success") }}
                        close={() => { setModalType(-1) }}
                    />,


                }[modalType]
            }
        </React.Fragment>
    )
}

export default Otdelka